<template>
  <div :class="!isDesktop ? 'md:px-2 lg:px-9' : 'md:px-9'">
    <div
      class="relative mt-12 ss:mt-14 md:mt-12 lg:mt-14 pl-2 lg:pl-0.5 ml-2 md:max-w-1400 md:mx-auto md:block"
    >
      <div class="flex flex-col items-start overflow-hidden w-full">
        <div class="relative">
          <div class="flex items-center gap-x-3">
            <img
              src="@/assets/svg/icons/icons-flower.svg"
              alt="Icono de flor"
              class="ml-6 w-6 h-6"
            />
            <span
              class="text-xl lg:text-2xl font-semibold font-bodoni tracking-wider"
              >{{ blok.title }}</span
            >
          </div>
          <div class="w-1 h-16 bg-[#3E5391] absolute top-1 left-2 z-10"></div>
        </div>
        <div class="w-full mt-4 pb-8">
          <div
            @click="handleChangeLeft(false)"
            v-if="isDesktop && leftArrow"
            class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -left-10 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
          >
            <img
              src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
              class="h-8"
            />
          </div>
          <div
            @scroll="isDesktop ? null : handleScroll($event)"
            ref="sliderContainer"
            class="flex flex-nowrap space-x-2.5 mx-auto h-full py-0 pl-0 pr-4 translate-x-0 transition-all duration-500 w-full snap-x snap-mandatory"
            :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
          >
            <div
              v-for="(product, index) in products"
              :key="index"
              class="cursor-pointer flex-shrink-0 p-4 mdplus:box-border mdplus:border-1 mdplus:border-transparent mdplus:hover:border-others-grey2 w-[180px] h-[460px] md:w-[180px] lg:w-[260px] overflow-y-hidden"
              :class="!isDesktop ? 'md:h-[540px] lg:h-[520px]' : 'md:h-[580px]'"
            >
              <Product :data="product" :currency="currency" />
            </div>
          </div>
          <div
            @click="handleChangeRight(true)"
            v-if="isDesktop && rightArrow"
            class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -right-10 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer"
          >
            <img
              src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
              class="h-8"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  currency: Object,
  global: Object,
})

const sliderContainer = ref(null)
const leftArrow = ref(false)
const rightArrow = ref(true)

const { isDesktop } = useDevice()

const products = ref([])

const productIds = props.blok.productsids.split(',').map((n) => parseInt(n))
const route = props.blok.routeproducts.route
const maxProducts = props.blok?.maxProducts
  ? parseInt(props.blok.maxProducts)
  : null

const globalIdProducts = props.global.products
const globalRouteProducts = props.global.routes

const getProducts = () => {
  if (productIds.length > 0) {
    let productsByIds = productIds.map((id) => {
      return globalIdProducts[id]
    })
    productsByIds.sort((a, b) => b.stock - a.stock)
    products.value = productsByIds
  } else {
    const routeSeotag = route.params.seotag
    let productsRoute = globalRouteProducts[routeSeotag]
    productsRoute.sort((a, b) => b.stock - a.stock)
    products.value = productsRoute
  }

  if (maxProducts) {
    products.value = products.value.slice(0, maxProducts)
  }
}

getProducts()

const maxScroll = computed(() => {
  if (!sliderContainer.value) return 0
  const child = sliderContainer.value.children[0]
  const childWidth = child.clientWidth
  return childWidth * sliderContainer.value.children.length + 1
})

const handleScroll = (e) => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const scrollWidth = sliderContainer.value.scrollWidth
  const scrollLeft = sliderContainer.value.scrollLeft

  actualTranslate.value = scrollLeft

  if (scrollLeft === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (scrollLeft + containerWidth === scrollWidth) {
    rightArrow.value = false
  } else {
    rightArrow.value = true
  }
}

const actualTranslate = ref(0)
const handleChangeRight = () => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const moveAmount = containerWidth * 0.96

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value + moveAmount
    }px)`
    actualTranslate.value = actualTranslate.value + moveAmount
  } else {
    sliderContainer.value.style.transform = `translateX(0px)`
    actualTranslate.value = 0
  }

  if (actualTranslate.value === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }
}

const handleChangeLeft = () => {
  if (!sliderContainer.value) return
  const containerWidth = sliderContainer.value.clientWidth
  const moveAmount = containerWidth * 0.96

  if (actualTranslate.value > 0) {
    sliderContainer.value.style.transform = `translateX(-${
      actualTranslate.value - moveAmount
    }px)`
    actualTranslate.value = actualTranslate.value - moveAmount
  } else {
    sliderContainer.value.style.transform = `translateX(${maxScroll.value}px)`
    actualTranslate.value = maxScroll.value
  }

  if (actualTranslate.value === 0) {
    leftArrow.value = false
  } else {
    leftArrow.value = true
  }

  if (actualTranslate.value < maxScroll.value - containerWidth) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }
}
</script>
